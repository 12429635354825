<template>
    <v-container>
        <div class="ml-4 mt-4">
            <h3 class="">{{ $t("message.title-others") }}</h3>
        </div>
        <div>
            <v-col class="" cols="12">
                <h4 class="">{{ $t("message.title-filters") }}</h4>
                <form>
                    <v-col
                            cols="12" md="2" xs="2" class="input-class"
                        >
                            <span class="">{{ $t("message.shop") }}</span>
                            <v-autocomplete
                            v-model="formOthers.shop_id"
                            @change="filterStore(formOthers.shop_id)"
                            class="p-0 mt-1"
                            :items="shopList"
                            item-text="store_name"
                            item-value="id"
                            outlined
                            ></v-autocomplete>
                        </v-col>
                    <v-row class="ml-4">
                        <v-col cols="12" md="6" xs="2" class="label-class">
                            <span class="">{{ $t("message.label-normalDeliveryFee") }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="input-class">
                            <v-text-field
                            id="input-style"
                            class="style-input"
                            outlined
                            v-model="formOthers.normalDeliveryFee"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" xs="2" class="label-class">
                            <span class="">{{ $t("message.label-group-deliveryFeeItem") }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="input-class">
                            <v-text-field
                            class="style-input"
                            id="input-style"
                            outlined
                            v-model="formOthers.groupDeliveryFeeItem"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" xs="2" class="label-class">
                            <span class="">{{ $t("message.label-groupMinQty") }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="input-class">
                            <v-text-field
                            class="style-input"
                            id="input-style"
                            outlined
                            v-model="formOthers.groupMinQty"
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" md="6" xs="2" class="label-class">
                            <span class="">{{ $t("message.label-max-bulk-group") }}</span>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="input-class">
                            <v-text-field
                            class="style-input"
                            id="input-style"
                            outlined
                            v-model="formOthers.maxGroup"
                            ></v-text-field>
                        </v-col> -->
                    </v-row>
                </form>
            </v-col>
        </div>
        <div class="d-flex flex-row-reverse mt-12 me-0" tile>
        <div class="m-2">
            <v-btn color="indigo" rounded type="submit" @click.prevent="submitOthers">
            <span style="color: white">{{ $t("message.submit-button") }}</span>
            </v-btn>
        </div>
        </div>
    </v-container>
</template>

<script>
import { localeData } from 'moment'
export default {
    name: "Others",
    data() {
        return {
            formOthers: {
                shop_id: "",
                normalDeliveryFee: "0",
                groupDeliveryFeeItem: "0",
                groupMinQty: "0",
                maxGroup: "0"
            },
            data: {}
            // shop: ['store 1', 'store 2', 'store 3']
        }
    },
    watch: {
        data: function(newVal, oldVal){
        }
    },

    computed: {
        shopList() {
            return this.$store.state.shop_module.shopAllResult
        },
    },
    methods: {
        async submitOthers() {
            await this.$store.dispatch("shop_module/updateOtherParams", {
                shop_id: this.formOthers.shop_id,
                delivery_fee: this.formOthers.normalDeliveryFee,
                delivery_fee_bulk: this.formOthers.groupDeliveryFeeItem,
                min_qty_bulk_group: this.formOthers.groupMinQty,
                max_bulk_group: this.formOthers.maxGroup
            })
            // await this.$store.dispatch("shop_module/fetchOtherParams", {
            //     shop_id: this.formOthers.shop_id
            // })
        },
        async fetchShopList() {
            await this.$store.dispatch("shop_module/fetchShopAll");
            let store = this.$store.state.shop_module.shopAllResult
            this.formOthers.shop_id = store[0].id
            await this.$store.dispatch("shop_module/fetchOtherParams",{
                store_id:  this.formOthers.shop_id
            })
            let dataOthers = this.$store.state.shop_module.resultOthersParams
            if(dataOthers.length == 0) {
                this.formOthers.normalDeliveryFee = 0
                this.formOthers.groupDeliveryFeeItem = 0
                this.formOthers.groupMinQty = 0
                this.formOthers.maxGroup = 0
            } else {
                // this.formOthers.shop_id = dataOthers.store_id
                this.formOthers.normalDeliveryFee = dataOthers.delivery_fee
                this.formOthers.groupDeliveryFeeItem = dataOthers.delivery_fee_bulk
                this.formOthers.groupMinQty = dataOthers.min_qty_bulk_group
                this.formOthers.maxGroup = dataOthers.max_bulk_group
            }
        },
        async filterStore(id) {
            await this.$store.dispatch("shop_module/fetchOtherParams", {
                store_id: id
            })
            let dataOthers = this.$store.state.shop_module.resultOthersParams
            if(dataOthers.length == 0) {
                this.formOthers.normalDeliveryFee = 0
                this.formOthers.groupDeliveryFeeItem = 0
                this.formOthers.groupMinQty = 0
                this.formOthers.maxGroup = 0
            } else {
                // this.formOthers.shop_id = dataOthers.store_id
                this.formOthers.normalDeliveryFee = dataOthers.delivery_fee
                this.formOthers.groupDeliveryFeeItem = dataOthers.delivery_fee_bulk
                this.formOthers.groupMinQty = dataOthers.min_qty_bulk_group
                this.formOthers.maxGroup = dataOthers.max_bulk_group
            }
        }
    },
    created() {
        this.fetchShopList()
        // this.filterStore()
    }
}
</script>

<style>
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;

}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 3px !important;
}
</style>